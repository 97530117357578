<template>
  <div class="ordersearch">
    <van-sticky>
      <van-search
        v-model="searText"
        placeholder="请输入搜索关键词"
        shape="round"
        show-action
        @focus="inputFocus"
        @blur="inputBlur"
        @input="inputText"
        ref="search"
        @search="onSearch"
      >
        <template #left>
          <van-dropdown-menu>
            <van-dropdown-item v-model="option1" :options="actionList" />
          </van-dropdown-menu>
        </template>
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
    </van-sticky>
    <div class="main" v-if="list.length > 0 ? true: false">
      <van-list
          v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="getSearch"
        >
          
          <div class="order_list" v-for="(item, index) in list" :key="index">
            <van-cell :border="false" center class="userinfo" @click="details(item.id)">
              <template #icon>
                <div>
                  <van-image
                    width="20px"
                    height="20px"
                    lazy-load
                    round
                    fit="cover"
                    style="display: block;margin-right: 5px;"
                    :src="item.avatar ? item.avatar : 'https://dk.midbest.cn/img/head.ede6594c.png'"
                  >
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                </div>
              </template>
              <template #title>
                <div class="van-ellipsis">{{item.nickname ? item.nickname : '未设置昵称'}}</div>
              </template>
              <template #right-icon>
                <span style="margin-right: 10px;">{{item.ordersn}}</span>
                <van-button size="mini" @click.stop="copay(item.ordersn)">复制</van-button>
              </template>
            </van-cell>
            <van-card
              v-for="(val, key) in item.goods"
              :key="key"
              :num="val.total"
              :price="val.realprice"
              :desc="val.optionname"
              :title="val.goodstitle"
              :thumb="val.goodsthumb"
              lazy-load
               @click="details(item.id)"
            >
            </van-card>
            <van-cell center @click="details(item.id)">
              <template #icon><span class="red">{{item.status_str}}</span></template>
              <template #title>
                <div class="order_total">
                  <span>实付：&yen;{{item.price}}</span>
                  <span v-if="item.dispatchprice && parseFloat(item.dispatchprice) > 0">(含运费：&yen;{{item.dispatchprice}})</span>
                  <span v-else>(免运费)</span>
                </div>
              </template>
            </van-cell>

            <van-cell center @click="details(item.id)">
              <template #title>
                <div class="order_total">
                  <span class="iconme" v-if="item.ydzg && item.iconme > 0">{{item.ydzg}}</span>
                  <span class="iconme">Y ({{item.agentcode1}})：&yen;{{item.commissions1_total}}</span>
                  <span class="iconme" v-if="item.commissions2 > 0">Y ({{item.agentcode2}})：&yen;{{item.commissions2_total}}</span>
                  <span class="iconme">C：&yen;{{item.iconme}}</span>
                </div>
              </template>
            </van-cell>

            <van-cell center @click="details(item.id)">
              <template #title>
                <div class="order_address">
                  <div class="order_express" v-if="item.expresssn">
                    <span>快递单号：</span>
                    <span style="flex:1;">{{item.expresssn}}</span>
                    <van-button size="mini" @click="copay(item.expresssn)">复制</van-button>
                  </div>
                </div>
              </template>
            </van-cell>

            <div class="order_footer" v-if="item.is_service_btn || item.is_service_btn2">
                <van-button type="default" block v-if="item.is_service_btn" @click="refund(item.id, item.status, item.is_outorder, item.check_status)">{{item.is_service_btn}}</van-button>

                <van-button type="default" block v-if="item.is_service_btn2" @click="cancelRefund(item.id)">{{item.is_service_btn2}}</van-button>
              </div>
          </div>
          
        </van-list>
    </div>
    <div class="main" v-if="shownodata">
      <van-empty description="暂无订单" />
    </div>
    <div id="loadingdiv" :style="'height:' + screeHeight + 'px;'" v-if="loadingdiv">
      <van-loading size="24px" type="spinner" vertical>加载中...</van-loading>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderSearch",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      orderactive: 0, // 订单状态
      loading: false,
      finished: false,
      currSize: 10, // 每次查询条数
      currOffset: 0, // 查询到哪里了
      list: [], // 展示内容
      shareid: 0,
      merchid: 0,
      strId: "", // 水印
      showList: false, // 显示搜索类别
      option1: 0,
      actionList: [
        { text: '电话', value: 0 },
        { text: '商品名', value: 1 },
        { text: '订单号', value: 2 },
        { text: '姓名', value: 3 },
        { text: '邀请码', value: 4 }
      ],
      searText: "", // 搜索关键字
      shownodata: false,
      loadingdiv: false, // 搜索中
      userinfo: {},
      showkefu: 0
    };
  },

  activated() {
    this.loading = false;
    this.currOffset = 0;
    this.init();
  },
  methods: {
    init() {
      setTimeout(() => {
        this.$refs.search.focus();
      }, 500);
    },
    getSearch() {
      let _that = null;
      _that = this;
      _that.loadingdiv = true;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("SHOP_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "shopsmobile/order/get_list",
          _that.$qs.stringify({
            searType: _that.option1,
            searText: _that.searText,
            size: _that.currSize,
            offset: _that.currOffset,
            active: 0
          })
        )
        .then(response => {
          _that.loading = false;
          _that.loadingdiv = false;
          if (response.data && response.data.code == 100000) {
            if (_that.currOffset == 0) {
              _that.list = response.data.data;
            }else {
              response.data.data.forEach(item => {
                _that.list.push(item);
              })
            }
            
            if (response.data.data.length >= _that.currSize) {
              _that.currOffset += _that.currSize;
              _that.finished = false;
              _that.shownodata = false;
            } else {
              _that.currOffset = response.data.data.length;
              _that.finished = true;
            }
          } else {
            _that.finished = true;
            if (_that.currOffset == 0) {
              _that.shownodata = true;
            }
            
          }
        })
        .catch(error => {
          _that.loading = false;
          _that.finished = true;
          if (_that.currOffset == 0) {
            _that.shownodata = true;
          }
          console.log(error);
        });
    },

    // 复制订单号
    copay(ordersn) {
      let _that = null;
      _that = this;
      _that.$copyText(ordersn)
      .then(
        function(e) {
          _that.$toast("复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      )
    },

    // 输入框获取焦点
    inputFocus(){
      console.log("val");
    },
    // 输入框失去焦点
    inputBlur(){
      console.log("val");
      if (this.searText == "") {
        // this.$router.go(-1);
      }
    },
    // 获取输入框内容
    inputText(val) {
      this.searText = val;
    },
    // 执行搜索
    onSearch(val) {
      if (val) {
        this.goods = [];
        this.currOffset = 0;
        this.getSearch();
      }
    },

    // 七鱼客服
    qiyukefu(index) {
      let _that = null;
      _that = this;
      let order = {};
      order = _that.list[index];
      console.log(order);

      if (window.qiyuSdkReady && (_that.merchid == 0 || this.showkefu == 1)) {
        let uid = 0;
        let name = "未登录—游客";
        let agentCode = "";
        let level = 1;
        let avatar = "";
        let levelname = "普通会员";
        let mobile = "";
        if (_that.userinfo && _that.userinfo.id > 0) {
          if (_that.userinfo.level == '6') {
            level = 2;
          }else if (_that.userinfo.level == '1') {
            level = 3;
          }else if (_that.userinfo.level == '14') {
            level = 4;
          }else if (_that.userinfo.level == '5') {
            level = 5;
          }
          uid = _that.userinfo.id;
          name = _that.userinfo.nickname;
          agentCode = _that.userinfo.agent_code;
          avatar = _that.userinfo.avatar;
          if (_that.userinfo.levelname) {
            levelname = _that.userinfo.levelname;
          }
          mobile = _that.userinfo.mobile;
        }else{
          _that.$toast("暂未开通");
          return false;
        }
    
        window.ysf('config', {
          uid: uid,
          name: name,
          level : level, // vip级别 5囤货， 14 CEO，1 顶级，6 特级
          data: JSON.stringify([
            {"index":0, "key":"avatar","label":"头像","value":avatar},
            {"index":1, "key":"agent_code", "label":"邀请码", "value":agentCode},
            {"index":2, "key":"level", "label":"等级", "value":levelname},
            {"index":3, "key":"mobile", "label":"手机号", "value":mobile},
            {"index":4, "key":"shopid", "label":"商户id", "value":_that.merchid},
            {"index":5, "key":"shopname", "label":"商户名称", "value":localStorage.getItem("DK_SHOPNAME")},
            {"index":6, "key":"shareid", "label":"分享者id", "value":_that.shareid},
          ]),
          success: function(){     // 成功回调
            window.ysf('product', {
              // sendByUser:1,
              show : 0, // 1为打开， 其他参数为隐藏（包括非零元素）
              title : order.goods[0].goodstitle,
              desc : "￥" + order.price,
              picture : order.goods[0].goodsthumb,
              note : order.ordersn,
              url : "",
              success: function(){
                window.ysf("open");
              },
              error: function() {
                console.log("sss");
              }
            })
          },
          error: function(){       // 错误回调
            // handle error
          }
        })
        
      }else{
        this.$toast("页面还未加载完，稍等一下");
      }
    },

    // 订单详情页
    details(id) {
      console.log(id);
      this.$router.push({
        name: "OrderDetail", 
        query: {
          id: id,
          xcx: this.xcx,
          t: new Date().getTime()
        }
      });
    },

    // 申请售后
    refund(id,status,orderOut,checkStatus) {
      if ((status==1 && (orderOut == 0 || orderOut==3)) || checkStatus==2) {
        this.$router.push({
          name: "serviceDetail", 
          query: {
            id: id,
            status: status,
            xcx: this.xcx,
            t: new Date().getTime()
          }
        });
      }else {
        this.$router.push({
          name: "RefundOrderOne", 
          query: {
            id: id,
            status: status,
            xcx: this.xcx,
            t: new Date().getTime()
          }
        });
      }
      
    },
    cancelRefund(id){
      this.$router.push({
        name: "serviceDetail", 
        query: {
          id: id,
          xcx: this.xcx,
          t: new Date().getTime()
        }
      });
    }
  }
};
</script>
<style lang="less">
.ordersearch {
  background-color: #ededed;
  width: 100%;
  min-height: 100%;
  padding-bottom: 60px;
  .van-search {
    padding: 5px 0;
    .van-dropdown-menu__item{
      width: 65px;
    }
    .van-search__content {
      margin-left: 10px;
      .van-cell {
        .van-cell__value {
          .van-field__body {
            .van-icon {
              margin-right: 10px;
            }
          }
        }
      }
    }
    .van-dropdown-menu__title{
      font-size: 13px;
    }
    .van-cell {
      padding: 10px;
      .van-cell__title {
        flex: 1;
      }
    }
    .van-cell--borderless {
      padding: 0;
      .van-cell__value{
        flex: 1;
        line-height: 40px;
      }
      .van-field__left-icon{
        line-height: 40px;
      }
    }
  }
  .main {
    // 订单列表
    .order_list {
      width: 100%;
      overflow: hidden;
      background-color: #fff;
      margin: 10px 0;
      &:last-of-type {
        margin-bottom: 0;
      }
      .van-cell {
        .van-cell__title {
          flex: 1;
          text-align: left;
          overflow: hidden;
          .order_total {
            width: 100%;
            text-align: right;
            .iconme {
              margin-left: 10px;
              color: #ee0a24;
              border: 1px solid #ee0a24;
              border-radius: 2px;
              padding: 1px 5px;
              font-size: 10px;
            }
          }
          .order_address {
            width: 100%;
            overflow: hidden;
            line-height: 24px;
            .order_address_str {
              line-height: 24px;
              &:first-of-type {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-items: baseline;
              }
            }
            span {
              margin-right: 5px;
              &:last-of-type {
                margin-right: 0;
              }
            }
          }
          .order_express {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-items: baseline;
            line-height: 24px;
          }
        }
      }
      .userinfo .van-cell__title {
        margin-right: 5px;
      }
      .van-card {
        background-color: #fff;
        text-align: left;
        margin-top: 0;
      }
      .red {
        color: #ee0a24;
      }
    }
    
    .red {
      color: #ee0a24;
    }
  }

  // 加载中
  #loadingdiv {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    .van-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -50px 0 0 -50px;
      padding: 10px 20px;
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      .van-loading__text{
        color: #fff;
      }
    }
  }
}
</style>